import { forwardRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useStore } from "@nanostores/react"
import cc from "classnames"

import { $auth, logout } from "@stores/auth"

import Link from "@components/Link"
import { BASE_CLASSNAMES, VERSIONS, SIZES } from "@components/Button"
import Card from "@components/Card"
import Image from "@components/Image"
import HollowNavCard from "./HollowNavCard"

import { ReactComponent as ChevronRight } from "@icons/chevron-right.svg"
import { ReactComponent as User } from "@icons/user.svg"
import { ReactComponent as Bookmark } from "@icons/bookmark-navigation.svg"
import { ReactComponent as Pencil } from "@icons/pencil.svg"
import { ReactComponent as ForkKnife } from "@icons/fork-knife.svg"
import { ReactComponent as Copy } from "@icons/copy.svg"

const MobileNavigationOpen = forwardRef<HTMLElement>((_, ref) => {
  const { user } = useStore($auth)
  const { t } = useTranslation<string>("common")

  const authenticatedNavItems = [
    { title: t("navigation.savedRecipes"), href: "/favorites", icon: <Bookmark fill="none" /> },
  ]

  const navItems = [
    { title: t("navigation.bundles"), href: "/b", icon: <Copy /> },
    { title: t("navigation.communityLink"), href: "/community", icon: <ForkKnife /> },
    { title: t("navigation.blog"), href: "/blog", icon: <Pencil /> },
  ]

  return (
    <nav ref={ref} className="h-full w-full overflow-scroll bg-white pt-16 px-16 pb-76">
      <Card class_name="relative mb-16 flex w-full items-center justify-between">
        {user ? (
          <>
            <div className="flex items-center">
              <div className="relative -mb-8 -ml-8 -mt-8 mr-12 h-48 w-48 overflow-hidden rounded-12">
                {user?.media?.permalink ? (
                  <Image
                    src={user?.media.permalink}
                    alt={user?.media.alt || user.user_name}
                    width={48}
                    height={48}
                    className="object-cover"
                  />
                ) : (
                  <User />
                )}
              </div>
              <Link href="/profile" class_name="expand-hitbox text-base" data-cy="nav-profile-link">
                <Trans
                  i18nKey="common:navigation.greeting"
                  components={[<strong key={0} />]}
                  values={{ name: user?.first_name || "" }}
                />
              </Link>
            </div>
            <div className="p-8 text-black bg-neutral-100 rounded-full">
              <ChevronRight className="w-16" />
            </div>
          </>
        ) : (
          <>
            <Link href="/sign-in" class_name="expand-hitbox text-base" data-cy="mobile-sign-in-link">
              {t("navigation.joinLink")}
            </Link>
            <div className="p-8 text-black bg-neutral-100 rounded-full">
              <ChevronRight className="w-16" />
            </div>
          </>
        )}
      </Card>
      {user && (
        <div className="mb-16">
          <Link class_name={cc(BASE_CLASSNAMES, VERSIONS.SPINACH, SIZES.LARGE, "block w-full")} href="/create-recipe">
            {t("navigation.createRecipe")}
          </Link>
        </div>
      )}
      <div className="-mx-8 mb-32 flex">
        <HollowNavCard image="/general/happy-plates-navigation-02.jpg" title={t("common.recipes")} link="/r" />
        <HollowNavCard image="/general/happy-plates-navigation-01.jpg" title={t("navigation.categories")} link="/c" />
      </div>

      <ul>
        {user &&
          authenticatedNavItems.map((item) => (
            <li
              key={item.href}
              className="relative flex items-center justify-between border-b border-neutral-200 py-16"
            >
              <div className="flex items-center">
                <div className="mr-8 p-8">
                  <div className="w-16">{item.icon}</div>
                </div>
                <Link href={item.href} class_name="expand-hitbox text-base">
                  {item.title}
                </Link>
              </div>
              <div className="p-8 text-black bg-neutral-100 rounded-full">
                <ChevronRight className="w-16" />
              </div>
            </li>
          ))}
        {navItems.map((item) => (
          <li key={item.href} className="relative flex items-center justify-between border-b border-neutral-200 py-16">
            <div className="flex items-center">
              <div className="mr-8 p-8">
                <div className="w-16">{item.icon}</div>
              </div>
              <Link href={item.href} class_name="expand-hitbox text-base">
                {item.title}
              </Link>
            </div>
            <div className="p-8 text-black bg-neutral-100 rounded-full">
              <ChevronRight className="w-16" />
            </div>
          </li>
        ))}
      </ul>

      <div className="flex flex-wrap items-center justify-between mt-32">
        <div>
          <p className="text-xs">© Happy Plates {new Date().getFullYear()}</p>
        </div>
        <div>
          {user ? (
            <>
              <Link href="/account/settings" class_name={cc(BASE_CLASSNAMES, VERSIONS.SECONDARY, SIZES.SMALL, "mr-8")}>
                {t("navigation.accountSettings")}
              </Link>
              <button
                onClick={async () => {
                  await logout()
                  location.href = "/"
                }}
                className={cc(BASE_CLASSNAMES, VERSIONS.SECONDARY, SIZES.SMALL)}
                data-cy="mobile-logout-button"
              >
                {t("common.logout")}
              </button>
            </>
          ) : (
            <Link
              href="/sign-in"
              class_name={cc(BASE_CLASSNAMES, VERSIONS.SECONDARY, SIZES.SMALL, "mr-8")}
              data-cy="mobile-login-button"
            >
              {t("common.login")}
            </Link>
          )}
          {/* <CountryPicker /> */}
        </div>
      </div>
    </nav>
  )
})

MobileNavigationOpen.displayName = "MobileNavigationOpen"

export default MobileNavigationOpen
