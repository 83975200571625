import Link from "@components/Link"
import Card from "@components/Card"
import Image from "@components/Image"

import { ReactComponent as ChevronRight } from "@icons/chevron-right.svg"

interface HollowNavCardProps {
  image: string
  link: string
  title: string
  onClick?: () => void
}

const HollowNavCard = ({ image, link, title, onClick }: HollowNavCardProps) => {
  return (
    <div className="w-2/4 px-8">
      <Card class_name="relative">
        <div className="relative mx-auto mb-8 mt-8 h-112 w-112 overflow-hidden rounded-full">
          <Image alt={title} src={image} width={112} height={112} placeholder="blur" className="object-cover" />
        </div>
        <div className="flex items-center justify-between">
          <Link href={link} class_name="expand-hitbox text-base" onClick={onClick}>
            {title}
          </Link>
          <div className="p-8 text-black bg-neutral-100 rounded-full">
            <ChevronRight className="w-16" />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default HollowNavCard
