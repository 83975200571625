import { useState, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { disableBodyScroll, enableBodyScroll } from "scroll"
import { useStore } from "@nanostores/react"
import cc from "classnames"
import { motion, AnimatePresence, useScroll } from "framer-motion"

import { $auth } from "@stores/auth"
import useSubsequentEffect from "@hooks/useSubsequentEffect"

import type { HeaderPropertiesDef, LayoutDef } from "@types"
import Container from "@components/Container"
import Link from "@components/Link"
import Image from "@components/Image"
import PreNav from "./PreNav"
import MobileNavigationOpen from "./MobileNavigationOpen"

import { ReactComponent as Search } from "@icons/search.svg"
import { ReactComponent as HappyPlatesLogo } from "src/assets/happy-plates-logo.svg"
import { ReactComponent as Smile } from "@icons/smile.svg"
import { ReactComponent as Hamburger } from "@icons/hamburger.svg"
import { ReactComponent as User } from "@icons/user.svg"
import "./Header.scss"

interface HeaderMobileProps {
  layout: LayoutDef
  header_properties: HeaderPropertiesDef
  title: string
  url: URL
}

interface NavigationProps {
  isMenuOpen: boolean
  isReduced: boolean
  url: URL
  onMenuToggleClick?: () => void
}

const Navigation = (props: NavigationProps): JSX.Element => {
  const { user } = useStore($auth)
  const { t } = useTranslation<string>("common")

  const isLinkActive = (href: string) => {
    return props.url.pathname === href
  }

  return (
    <Container
      class_name={cc({
        "h-[var(--hp-navigation-reduced-header-height)]": props.isReduced,
        "h-[var(--hp-navigation-menu-height)]": !props.isReduced,
      })}
    >
      <div
        className="grid grid-cols-3 h-full w-full border-b border-[color:var(--\_border-color)]"
        data-cy="header-mobile"
      >
        <div className="col-span-1 flex items-center justify-start w-32">
          <Link
            href={"/r"}
            class_name={cc("rounded-8 p-4 transition-colors", { "bg-neutral-100": isLinkActive("/r") })}
            data-cy="mobile-nav-search"
          >
            <span className="sr-only">{t("navigation.search")}</span>
            <div className="w-24">
              <Search />
            </div>
          </Link>
        </div>

        <div className="col-span-1 flex justify-center items-center">
          {props.isReduced ? (
            <div className="w-32">
              <Link href="/">
                <Smile />
              </Link>
            </div>
          ) : (
            <div className="w-56">
              <Link href="/">
                <HappyPlatesLogo />
              </Link>
            </div>
          )}
        </div>

        <div className="col-span-1 flex flex-row items-center justify-end">
          {user && (
            <>
              <div className="flex items-center">
                <Link
                  href="/profile"
                  class_name={cc("relative mr-16 h-32 w-32 overflow-hidden rounded-8", {
                    "border border-black-alpha-100": user?.media?.permalink,
                  })}
                >
                  {user?.media?.permalink ? (
                    <Image
                      src={user?.media.permalink}
                      alt={user?.media.alt || user.user_name}
                      width={48}
                      height={48}
                      className="object-cover"
                    />
                  ) : (
                    <User />
                  )}
                </Link>
              </div>
            </>
          )}
          <button
            className={cc("rounded-8 p-4 transition-colors", { "bg-neutral-100": props.isMenuOpen })}
            onClick={() => props.onMenuToggleClick?.()}
            data-cy="mobile-nav-toggle"
          >
            <span className="sr-only">{t("navigation.menu")}</span>
            <div className="w-24">
              <Hamburger className={cc("hamburger-nav", { open: props.isMenuOpen })} />
            </div>
          </button>
        </div>
      </div>
    </Container>
  )
}

const HeaderMobile = (props: HeaderMobileProps): JSX.Element => {
  const { header_properties } = props
  const { bg_color = "white" } = header_properties

  const ref = useRef<HTMLDivElement>(null)
  const mobileNavigationOpenRef = useRef<HTMLDivElement>(null)
  const scrollYValue = useRef<number>(0)
  const lastScrollYValue = useRef<number>(0)

  const { scrollY } = useScroll()

  const [isTop, setIsTop] = useState(true)
  const [hasScrolled, setHasScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [theme, setTheme] = useState<"white" | "transparent">(bg_color)

  const handleThemeColor = () => {
    if (isMenuOpen) {
      return setTheme("white")
    }

    return setTheme(bg_color)
  }

  useSubsequentEffect(() => {
    lastScrollYValue.current = isMenuOpen ? scrollYValue.current : 0

    handleThemeColor()
  }, [isMenuOpen])

  useEffect(() => {
    const unsubscribeScrollListener = scrollY.on("change", (latest) => {
      setIsTop(latest === 0)
      setHasScrolled(latest > 200)
      scrollYValue.current = latest
    })

    return () => {
      unsubscribeScrollListener()
    }
  }, [scrollY])

  /**
   * When opening the menu the scroll lock package causes the scroll position to get set to 0 (to the top).
   * We track the last scroll value before opening the menu in order to work around this behavior.
   */
  const showReducedHeader =
    hasScrolled ||
    lastScrollYValue.current > 200 ||
    (isMenuOpen && !isTop) ||
    (isMenuOpen && lastScrollYValue.current !== 0)

  return (
    <>
      {!showReducedHeader && (
        <div
          className={cc("header-mobile", {
            "z-50 fixed inset-x-0": isMenuOpen,
            "z-50 absolute inset-x-0": !isMenuOpen,
          })}
          data-theme={theme}
        >
          <PreNav layout={props.layout} bg_color={bg_color} />
          <Navigation
            url={props.url}
            isMenuOpen={isMenuOpen}
            isReduced={false}
            onMenuToggleClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </div>
      )}

      <AnimatePresence>
        {showReducedHeader && (
          <motion.div
            className="header-mobile fixed top-0 inset-x-0 z-50"
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            transition={{ ease: "easeOut", duration: 0.3 }}
            data-theme="white"
          >
            <Navigation
              url={props.url}
              isMenuOpen={isMenuOpen}
              isReduced={true}
              onMenuToggleClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            ref={ref}
            className={cc("fixed z-[100000] inset-0", {
              "top-[var(--hp-navigation-reduced-header-height)]": showReducedHeader,
              "top-[var(--hp-navigation-header-height)]": !showReducedHeader,
            })}
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ ease: "easeOut", duration: 0.3 }}
            onAnimationStart={(e: { y: number | string }) => {
              if (e.y === "100%") enableBodyScroll(mobileNavigationOpenRef.current)
            }}
            onAnimationComplete={(e: { y: number | string }) => {
              if (e.y === 0) disableBodyScroll(mobileNavigationOpenRef.current)
            }}
          >
            <MobileNavigationOpen ref={mobileNavigationOpenRef} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default HeaderMobile
